:root {
  --black: #1b1b1e;
  --dark-grey: #39383a;
  --white: #fff;
  --green: #59704f;
  --light-green: #a7b8a5;
  --pale-green: #edf3e5;
  --bg: var(--pale-green);
  --bg-alt: var(--light-green);
  --bg-accent: var(--dark-grey);
  --fg: var(--black);
  --fg-alt: var(--black);
  --fg-accent: var(--white);
  --accent: var(--green);
  --accent-alt: var(--light-green);
}

body {
  margin: 0;
  font-family: 'Bellota Text', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
  color: var(--fg);
  font-size: 1.125em;
  letter-spacing: 0.05em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: .1em;
  line-height: 1;
}

h1 {
  font-size: clamp(30px, 10px + 4vw, 55px);
}

a, a:hover, a:active, a:focus {
  color: var(--accent);
}
